import React, { useEffect, useState, useMemo } from "react";
import {
  Text,
  Dropdown,
  Option,
  makeStyles,
  Spinner,
  Button,
  tokens
} from "@fluentui/react-components";
import { db } from "./../firebase-config";
import { getDocs, collection } from "firebase/firestore";
import SummaryCards from "./SummaryCards";
import CompliancePanel from "./CompliancePanel";
import ProductivityChart from "./ProductivityChart";
import EngagementScoreboard from "./EngagementScoreboard";
import UserSessionSummaryCard from "./UserSessionSummaryCard";
import { fetchSessionsInRange } from "./logic/sessionsAnalytics";

interface UserDoc {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  officeLocation: string;
  team?: string;
  role: string;
}

interface SessionDoc {
  userId: string;
  date: string; // 'YYYY-MM-DD'
  totalActiveMinutes: number;
  opportunityConversations?: string[];
  uniqueSeen?: string[];
}

type Granularity = "daily" | "weekly" | "monthly";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  filtersRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    alignItems: "center",
    marginBottom: "10px"
  },
  topCardsRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    marginBottom: "16px"
  },
  spinnerContainer: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "center"
  },
  userTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    textAlign: "left",
    borderBottom: "2px solid #ccc",
    padding: "8px",
    backgroundColor: tokens.colorNeutralBackground1,
  },
  td: {
    borderBottom: `1px solid ${tokens.colorNeutralForeground3}`,
        backgroundColor: tokens.colorNeutralBackground1,
    padding: "8px",
  },

  /* new row container for the sub components */
  subComponentsRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "16px",
  },
  subComponentItem: {
    flex: "49%",
    minWidth: "350px",  // ensures a decent size on smaller screens
    boxSizing: "border-box"
  },
  heading: {
    marginTop: "16px",
    fontWeight: "500"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column"
  }

});

const AnalyticsDashboard: React.FC = () => {
  const styles = useStyles();

  // Filters
  const [selectedOffice, setSelectedOffice] = useState("All");
  const [selectedTeam, setSelectedTeam] = useState("All");
  const [granularity, setGranularity] = useState<Granularity>("daily");

  // Firestore data
  const [users, setUsers] = useState<UserDoc[]>([]);
  const [allSessions, setAllSessions] = useState<SessionDoc[]>([]);

  // Loading states
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingSessions, setLoadingSessions] = useState(false);

  // For user drill-down
  const [expandedUserId, setExpandedUserId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingUsers(true);
      const snap = await getDocs(collection(db, "users"));
      const loaded: UserDoc[] = snap.docs.map((docSnap) => ({
        uid: docSnap.id,
        ...(docSnap.data() as UserDoc),
      }));
      setUsers(loaded);
      setLoadingUsers(false);
    };
    fetchUsers();
  }, []);

  // Filtered user list
  const filteredUsers = useMemo(() => {
    let result = users;
    if (selectedOffice !== "All") {
      result = result.filter((u) => u.officeLocation === selectedOffice);
    }
    if (selectedTeam !== "All") {
      result = result.filter((u) => u.team === selectedTeam);
    }
    return result;
  }, [users, selectedOffice, selectedTeam]);

  // date range from granularity
  const { startDate, endDate } = useMemo(() => {
    const now = new Date();
    const end = new Date(now);
    const start = new Date(now);

    switch (granularity) {
      case "daily":
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        break;
      case "weekly":
        start.setDate(end.getDate() - 7);
        break;
      case "monthly":
        start.setDate(end.getDate() - 30);
        break;
    }
    return { startDate: start, endDate: end };
  }, [granularity]);

  useEffect(() => {
    setLoadingSessions(true);
  
    const doFetch = async () => {
      const fetched = await fetchSessionsInRange(startDate, endDate);
      setAllSessions(fetched);
      setLoadingSessions(false);
    };
  
    doFetch();
  }, [startDate, endDate]);

  const filteredSessions = useMemo(() => {
    const userIdSet = new Set(filteredUsers.map((u) => u.uid));
    return allSessions.filter((s) => userIdSet.has(s.userId));
  }, [allSessions, filteredUsers]);

  // Distinct offices & teams
  const distinctOffices = useMemo(() => {
    return ["All", ...Array.from(new Set(users.map((u) => u.officeLocation)))];
  }, [users]);
  const distinctTeams = useMemo(() => {
    return ["All", ...Array.from(new Set(users.map((u) => u.team)))];
  }, [users]);

  const toggleExpandUser = (userId: string) => {
    setExpandedUserId(expandedUserId === userId ? null : userId);
  };

  return (
    <div className={styles.container}>
      <Text size={500}>Sales Manager Analytics Dashboard</Text>

      {/* Filters Row */}
      <div className={styles.filtersRow}>
        <Text>Office:</Text>
        <Dropdown
          value={selectedOffice}
          onOptionSelect={(_, data) => setSelectedOffice(data.optionValue as string)}
        >
          {distinctOffices.map((office) => (
            <Option key={office} value={office}>
              {office}
            </Option>
          ))}
        </Dropdown>

        <Text>Team:</Text>
        <Dropdown
          value={selectedTeam}
          onOptionSelect={(_, data) => setSelectedTeam(data.optionValue as string)}
        >
          {distinctTeams.map((team) => (
            <Option key={team} value={team}>
              {team}
            </Option>
          ))}
        </Dropdown>

        <Text>Granularity:</Text>
        <Dropdown
          value={granularity}
          onOptionSelect={(_, data) => setGranularity(data.optionValue as Granularity)}
        >
          <Option value="daily">Daily</Option>
          <Option value="weekly">Weekly</Option>
          <Option value="monthly">Monthly</Option>
        </Dropdown>
      </div>

      {/* Loading states */}
      {loadingUsers && (
        <div className={styles.spinnerContainer}>
          <Spinner size="medium" label="Loading Users..." />
        </div>
      )}
      {loadingSessions && (
        <div className={styles.spinnerContainer}>
          <Spinner size="medium" label="Loading Sessions..." />
        </div>
      )}

      {/* Summary Cards at top */}
      {!loadingUsers && !loadingSessions && (
        <div className={styles.topCardsRow}>
          <SummaryCards
            users={filteredUsers}
            sessions={filteredSessions}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}

      {/* Sub components in a flex row, 2 per row */}
      {!loadingUsers && !loadingSessions && (
        <div className={styles.subComponentsRow}>
          {/* Compliance Panel */}
          <div className={styles.subComponentItem}>
            <CompliancePanel
              users={filteredUsers}
              sessions={filteredSessions}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          {/* Engagement Scoreboard */}
          <div className={styles.subComponentItem}>
            <EngagementScoreboard
              users={filteredUsers}
              sessions={filteredSessions}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          {/* Productivity Chart */}
          <div className={styles.subComponentItem}>
            <ProductivityChart
              users={filteredUsers}
              sessions={filteredSessions}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          
        </div>
      )}

      {/* Detailed user table with drill-down */}
      {!loadingUsers && !loadingSessions && filteredUsers.length > 0 && (
        <>
        <div className={styles.headingContainer}>
            <Text size={400} className={styles.heading}>Breakout Table</Text>
            <Text size={300}>
                Breakout an individual users stats!
            </Text>
        </div>
        <table className={styles.userTable} style={{ marginTop: "16px" }}>
          <thead>
            <tr>
              <th className={styles.th}>User</th>
              <th className={styles.th}>Email</th>
              <th className={styles.th}>Office</th>
              <th className={styles.th}>Team</th>
              <th className={styles.th}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((u) => (
              <React.Fragment key={u.uid}>
                <tr>
                  <td className={styles.td}>{u.firstName} {u.lastName}</td>
                  <td className={styles.td}>{u.email}</td>
                  <td className={styles.td}>{u.officeLocation}</td>
                  <td className={styles.td}>{u.team}</td>
                  <td className={styles.td}>
                    <Button onClick={() => toggleExpandUser(u.uid)}>
                      {expandedUserId === u.uid ? "Hide Details" : "Show Details"}
                    </Button>
                  </td>
                </tr>
                {expandedUserId === u.uid && (
                  <tr>
                    <td colSpan={5} className={styles.td}>
                      <UserSessionSummaryCard
                        user={u}
                        sessions={allSessions.filter(s => s.userId === u.uid)}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        </>
      )}
    </div>
  );
};

export default AnalyticsDashboard;
