// sessionsAnalytics.ts
import { db } from "../../firebase-config";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  orderBy,
  startAt,
  endAt,
  Timestamp,
  getDoc
} from "firebase/firestore";
import { getTimeRange } from "./dateUtils";

interface DailySessionDoc {
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
  lastActivity: Timestamp;
  totalActiveMinutes: number;
  isTimedOut: boolean;
}

/**
 * For each date in [startDate, endDate], we fetch /sessions/{YYYY-MM-DD}/users
 * for the specified user IDs. We return an array of dailyDoc records.
 * Optionally: We could also fetch subcollection logs if needed.
 */
export async function fetchSessionDocs(
  userIds: string[],
  startDate: Date,
  endDate: Date
): Promise<DailySessionDoc[]> {
  const allDailyDocs: DailySessionDoc[] = [];

  // We'll generate a date range from startDate to endDate in daily increments
  // Then for each day, fetch the relevant user docs in /sessions/{dateStr}/users
  let curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dateStr = curDate.toISOString().split("T")[0]; // e.g. '2025-03-29'

    const usersCollRef = collection(db, "sessions", dateStr, "users");
    // If you want only certain user IDs, you can do a query with a `where("userId", "in", userIds)` check
    if (userIds.length > 0) {
      const q = query(usersCollRef, where("userId", "in", userIds));
      const snap = await getDocs(q);
      snap.forEach((docSnap) => {
        allDailyDocs.push(docSnap.data() as DailySessionDoc);
      });
    } else {
      // If no userIds, either skip or load everything
      const snap = await getDocs(usersCollRef);
      snap.forEach((docSnap) => {
        allDailyDocs.push(docSnap.data() as DailySessionDoc);
      });
    }

    // Move to the next day
    curDate.setDate(curDate.getDate() + 1);
  }

  return allDailyDocs;
}

/**
 * Pseudocode for daily doc approach. 
 * We loop from startDate to endDate day by day, and for each user ID, fetch doc `/sessions/{date}/users/{uid}`
 */
export async function fetchSessionsInRange(
    startDate: Date,
    endDate: Date
  ): Promise<any[]> {
    const results: any[] = [];
  
    let cur = new Date(startDate.getTime());
    while (cur <= endDate) {
      const dateStr = cur.toISOString().split("T")[0]; // 'YYYY-MM-DD'
  
      const usersColRef = collection(db, "sessions", dateStr, "users");
      const userDocsSnap = await getDocs(usersColRef);
  
      userDocsSnap.forEach((docSnap) => {
        results.push({
          date: dateStr,
          userId: docSnap.id,
          ...docSnap.data(),
        });
      });
  
      cur.setDate(cur.getDate() + 1);
    }
  
    return results;
  }