// CompliancePanel.tsx
import React, { useMemo } from "react";
import { makeStyles, Text, tokens } from "@fluentui/react-components";

interface UserDoc {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  officeLocation: string;
  team?: string;
  // etc.
}

interface SessionDoc {
    userId: string;
    date: string; // 'YYYY-MM-DD'
    totalActiveMinutes: number;
    opportunityConversations?: string[];
  }

interface CompliancePanelProps {
  users: UserDoc[];
  sessions: SessionDoc[];
  startDate: Date;
  endDate: Date;
}

const useStyles = makeStyles({
  container: {
    marginTop: "16px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "8px",
  },
  th: {
    textAlign: "left",
    borderBottom: "2px solid #ccc",
    padding: "6px 8px",
    backgroundColor: tokens.colorNeutralBackground1,
  },
  td: {
    borderBottom: `1px solid ${tokens.colorNeutralForeground3}`,
    backgroundColor: tokens.colorNeutralBackground1,
    padding: "6px 8px",
  },
  heading: {
    marginTop: "16px",
    fontWeight: "500"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column"
  }
});

/**
 * This component checks how many days in [startDate..endDate] each user has a session doc,
 * then displays the ratio (#daysWithDoc / #totalDays).
 */
const CompliancePanel: React.FC<CompliancePanelProps> = ({
  users,
  sessions,
  startDate,
  endDate,
}) => {
  const styles = useStyles();

  // 1) Build an array of daily strings (e.g. ['2025-03-31','2025-04-01',...]) for the range
  const dateStrings = useMemo(() => {
    const dates: string[] = [];
    const cur = new Date(startDate.getTime());
    while (cur <= endDate) {
      const ds = cur.toISOString().split("T")[0];
      dates.push(ds);
      cur.setDate(cur.getDate() + 1);
    }
    return dates;
  }, [startDate, endDate]);

  const totalDays = dateStrings.length;

  // 2) Create a quick lookup for (userId, dateStr) => hasDoc
  const sessionLookup = useMemo(() => {
    // We'll store e.g. 'userId|dateStr' => true
    const lookup: Record<string, boolean> = {};
    sessions.forEach((s) => {
      const key = `${s.userId}|${s.date}`;
      lookup[key] = true;
    });
    return lookup;
  }, [sessions]);

  // 3) For each user, count how many days in dateStrings they have a doc
  //    Then compute compliance ratio (#daysHasDoc / totalDays).
  const complianceData = useMemo(() => {
    return users.map((u) => {
      let dayCount = 0;
      for (const ds of dateStrings) {
        const key = `${u.uid}|${ds}`;
        if (sessionLookup[key]) {
          dayCount++;
        }
      }
      return {
        user: u,
        daysWithDoc: dayCount,
        totalDays: totalDays,
        percent: totalDays > 0 ? (dayCount / totalDays) * 100 : 0
      };
    });
  }, [users, dateStrings, sessionLookup, totalDays]);

  return (
    <div className={styles.container}>
        <div className={styles.headingContainer}>
            <Text size={500} className={styles.heading}>
            Compliance Panel
            </Text>
            <Text size={300}>
                Checking how many days in the selected date range each user has at least one session doc.
            </Text>
        </div>
      
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>User</th>
            <th className={styles.th}>Email</th>
            <th className={styles.th}>Days With Doc</th>
            <th className={styles.th}>Compliance</th>
          </tr>
        </thead>
        <tbody>
          {complianceData.map((cd) => (
            <tr key={cd.user.uid}>
              <td className={styles.td}>{cd.user.firstName} {cd.user.lastName}</td>
              <td className={styles.td}>{cd.user.email}</td>
              <td className={styles.td}>{cd.daysWithDoc} / {cd.totalDays}</td>
              <td className={styles.td}>{cd.percent.toFixed(1)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompliancePanel;
