// ProductivityChart.tsx
import React, { useMemo } from "react";
import { makeStyles, Text } from "@fluentui/react-components";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

interface UserDoc {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    officeLocation: string;
    team?: string;
    role: string;
  }

  interface SessionDoc {
    userId: string;
    date: string;
    totalActiveMinutes: number;
    opportunityConversations?: string[];
    uniqueSeen?: string[];
  }

interface ProductivityChartProps {
  users: UserDoc[];
  sessions: SessionDoc[];
  startDate: Date;
  endDate: Date;
}

const useStyles = makeStyles({
  container: {
    marginTop: "16px",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "1px 2px 4px rgba(0,0,0,0.1)",
    padding: "16px"
  },
  chartTitle: {
    marginBottom: "8px",
  },
  heading: {
    marginTop: "16px",
    fontWeight: "500"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column"
  }
});

/**
 * Renders a daily line chart showing how many opportunity emails
 * and how many total active minutes were recorded each day across
 * the selected users.
 */
const ProductivityChart: React.FC<ProductivityChartProps> = ({
    users,
    sessions,
    startDate,
    endDate
  }) => {
    const styles = useStyles();
  
    // array of date strings
    const dateStrings = useMemo(() => {
      const dates: string[] = [];
      const cur = new Date(startDate.getTime());
      while (cur <= endDate) {
        dates.push(cur.toISOString().split("T")[0]);
        cur.setDate(cur.getDate() + 1);
      }
      return dates;
    }, [startDate, endDate]);
  
    // aggregator
    const chartData = useMemo(() => {
      const aggregator: Record<string, { activeMins: number; oppEmails: number; nonBiddable: number }> = {};
  
      // init aggregator for each day
      dateStrings.forEach(ds => {
        aggregator[ds] = { activeMins: 0, oppEmails: 0, nonBiddable: 0 };
      });
  
      // sum up
      sessions.forEach(s => {
        const rec = aggregator[s.date];
        if (rec) {
          rec.activeMins += s.totalActiveMinutes;
          rec.oppEmails += (s.opportunityConversations?.length || 0);
          rec.nonBiddable += (s.uniqueSeen?.length || 0);
        }
      });
  
      return dateStrings.map(ds => ({
        date: ds,
        activeMins: aggregator[ds].activeMins,
        oppEmails: aggregator[ds].oppEmails,
        nonBiddable: aggregator[ds].nonBiddable
      }));
    }, [sessions, dateStrings]);
  
    return (
        <>
    <div className={styles.headingContainer}>
        <Text size={400} className={styles.heading}>Productivity Chart</Text>
        <Text size={300}>
            Daily total active minutes vs. opportunity emails vs. non-biddable emails.
        </Text>
    </div>
      <div className={styles.container}>
        
  
        <div style={{ width: "100%", height: 300, marginTop: "16px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="activeMins"
                stroke="#8884d8"
                name="Active Minutes"
              />
              <Line
                type="monotone"
                dataKey="oppEmails"
                stroke="#82ca9d"
                name="Opp Emails"
              />
              <Line
                type="monotone"
                dataKey="nonBiddable"
                stroke="#ff7300"
                name="Non-Biddable Emails"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      </>
    );
  };
  
  export default ProductivityChart;