// SummaryCards.tsx
import React, { useMemo } from "react";
import { makeStyles, Card, CardHeader, Text } from "@fluentui/react-components";

interface UserDoc {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    officeLocation: string;
    team?: string;
    role: string;
  }

  interface SessionDoc {
    userId: string;
    date: string;
    totalActiveMinutes: number;
    opportunityConversations?: string[];
    uniqueSeen?: string[];
  }

interface SummaryCardsProps {
  users: UserDoc[];
  sessions: SessionDoc[];
  startDate: Date;
  endDate: Date;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
  },
  card: {
    minWidth: "180px",
    boxShadow: "1px 2px 4px rgba(0,0,0,0.1)",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column"
  },
  cardBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px"
  }
});

/**
 * Displays a row of summary metric cards for the selected date range.
 * Metrics:
 *  - Total Sessions
 *  - Total Active Minutes
 *  - Total Opportunity Emails
 */
const SummaryCards: React.FC<SummaryCardsProps> = ({ users, sessions, startDate, endDate }) => {
  const styles = useStyles();

  // Example 1: total sessions = how many session docs we have
  const totalSessions = useMemo(() => {
    return sessions.length;
  }, [sessions]);

  // Example 2: sum of active minutes across all sessions
  const totalActiveMinutes = useMemo(() => {
    return sessions.reduce((sum, s) => sum + (s.totalActiveMinutes || 0), 0);
  }, [sessions]);

  // Example 3: total opportunity emails (summed from each session's array length)
  const totalOppEmails = useMemo(() => {
    return sessions.reduce((sum, s) => sum + (s.opportunityConversations?.length || 0), 0);
  }, [sessions]);

  // new: sum of uniqueSeen (non-biddable) emails
  const totalNonBiddable = useMemo(() => {
    return sessions.reduce((sum, s) => sum + (s.uniqueSeen?.length || 0), 0);
  }, [sessions]);

  // You can add more metrics (bid counts, average response times, etc.) similarly

  return (
    <div className={styles.container}>
      {/* Card: Total Sessions */}
      <Card className={styles.card}>
        <div style={{display: "flex", alignItems: "center"}}>
          <Text size={300}>Total Sessions</Text>
        </div>
        <div className={styles.cardBody}>
          <Text size={600}>{totalSessions}</Text>
        </div>
      </Card>

      {/* Card: Total Active Minutes */}
      <Card className={styles.card}>
        <div style={{display: "flex", alignItems: "center"}}>
          <Text size={300}>Total Active Minutes</Text>
        </div>
        <div className={styles.cardBody}>
          <Text size={600}>{totalActiveMinutes.toFixed(1)}</Text>
        </div>
      </Card>

      {/* Card: Opportunity Emails */}
      <Card className={styles.card}>
        <div style={{display: "flex", alignItems: "center"}}>

          <Text size={300}>Opportunity Emails</Text>
        </div>
        <div className={styles.cardBody}>
          <Text size={600}>{totalOppEmails}</Text>
        </div>
      </Card>

      {/* Card: non-biddable emails (uniqueSeen) */}
      <Card className={styles.card}>
      <div style={{display: "flex", alignItems: "center"}}>
          <Text size={300}>Non-Biddable Emails</Text>
          </div>
        <div className={styles.cardBody}>
          <Text size={600}>{totalNonBiddable}</Text>
        </div>
      </Card>
    </div>
  );
};

export default SummaryCards;
