import * as React from "react";
import { Image, tokens, makeStyles, Button, Theme, Link } from "@fluentui/react-components";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification  } from "firebase/auth"
import { auth } from "../firebase-config"
import {  SearchFilled,  SignOutFilled, WeatherSunnyLowRegular, DarkThemeFilled, } from "@fluentui/react-icons";
import { useState, useContext, useEffect, Suspense } from "react";
import {AuthContext} from '../data/DataProvider';



export interface HeaderProps {
  title: string;
  logo: string;
  message: string;
  theme: string;
  onToggle: () => void;
  onSwitchPage: (string: string) => void;
}

const useStyles = makeStyles({
  welcome__header: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: tokens.colorNeutralStrokeOnBrand,
    paddingLeft: "10px",
    boxShadow: "1px 2px 7px #888888",
    gap: "5px",
    paddingRight: "20px",
  },
  message: {
    fontSize: "30px",
    fontWeight: tokens.fontWeightRegular,
    fontFamily: "Orbitron",
    fontColor: tokens.colorNeutralBackgroundStatic,
    margin: 0,
  },
  image: {
    display: "flex",
  },
  logOutButton:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "80px",
    height: "40px",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: 1,
    padding: "0px",
    textAlign: "center",
    "&:hover": {
    backgroundColor: tokens.colorCompoundBrandBackgroundHover,
    },
    
    "&:hover:active": {
    backgroundColor: tokens.colorCompoundBrandBackgroundPressed,
    },
    flexGrow: 2,
    
},
icon: {
    fontSize: "40px",
    color: "#fff",
    paddingLeft: "3px", 
    maxWidth: "33px",
    flexGrow: 1,
},
rootButton: { 
    fontSize: "16px", 
    padding: "12px 20px", 
    borderRadius: "8px",
    flexGrow: 2,
     
},
toHome: {
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    alignItems: "center"
},
proMode: {
    padding: "0px",
    fontFamily: "Orbitron",
    margin: "0px",
    marginLeft: "5px"
}
});

const ThemedButton: React.FC<{ toggleTheme: () => void }> = ({ toggleTheme }) => {
    const styles = useStyles();

    return (
      <Button
        onClick={toggleTheme}
        className={styles.rootButton}
      >Toggle Theme</Button>
    );
  };

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { title, logo, message, theme, onToggle, onSwitchPage } = props;
  const styles = useStyles();

      const { user, userData, loading } = useContext(AuthContext);
  

  const logOut = async () => {
          try {
              await signOut(auth)
          } catch(err){
              console.log(err)
          }
      }

  const handleToggle = () => {
    onToggle()
  }

  const switchPage = (page: string) => {
    onSwitchPage(page)
  }

  const handleNavAway = () => {
    // URL of the website you want to open
    const url = 'https://beebot-65cdc--test-zf7tox7m.web.app';
    
    // Opens the website in a new tab
    window.open(url, '_blank');
};

  return (
    <section className={styles.welcome__header}>
      <span onClick={() => {switchPage("Home")}} className={styles.toHome}>
      <Image width="55" height="55" src={logo} alt={title} className={styles.image}/>
      <h4 className={styles.message}>{message}</h4>
      {(userData && userData.role === "admin") || (userData && userData.role === "manager") || (userData && userData.role === "powerUser") ?<p className={styles.proMode}>PRO</p> : null }
      </span>  
      {/* <Link onClick={handleNavAway} >Old Beebot</Link> */}
      {/* {(userData && userData.role === "admin") || (userData && userData.role === "manager") ? <Link onClick={() => {switchPage("RFP")}} >RFP Center</Link>  : null} */}
      {(userData && userData.role === "admin") ? <Link onClick={() => {switchPage("Admin")}} className={styles.proMode} >Admin Center</Link>  : null}
      {(userData && userData.role === "admin") || (userData && userData.role === "manager") ? <Link onClick={() => {switchPage("Beemail")}} className={styles.proMode} >Beemail Tracking</Link>  : null}

      <Button appearance="primary" disabled={false} size="small" onClick={logOut} className={styles.logOutButton}>
        Logout
        <SignOutFilled className={styles.icon}/>
      </Button>
      {theme === "Light Mode" 
    ?
      <Button appearance="primary" disabled={false} size="small" onClick={handleToggle} className={styles.logOutButton}>
        Light Mode
        <WeatherSunnyLowRegular className={styles.icon}/>
      </Button>
    :
      <Button appearance="primary" disabled={false} size="small" onClick={handleToggle} className={styles.logOutButton}>
        Dark Mode
        <DarkThemeFilled className={styles.icon}/>
      </Button>
    }
      
      
    </section>
  );
};

export default Header;
