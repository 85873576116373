// UserSessionSummaryCard.tsx
import React, { useMemo } from "react";
import { makeStyles, Card, CardHeader, Text, tokens } from "@fluentui/react-components";

interface UserDoc {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    officeLocation: string;
    team?: string;
    role: string;
  }

  interface SessionDoc {
    userId: string;
    date: string;
    totalActiveMinutes: number;
    opportunityConversations?: string[];
    uniqueSeen?: string[];
  }

interface UserSessionSummaryCardProps {
  user: UserDoc;
  sessions: SessionDoc[];    // The sessions for that user in the selected range
  activities?: any[];        // e.g. if you track logs (not used in this example)
}

const useStyles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: "1px 2px 4px rgba(0,0,0,0.1)",
    padding: "10px",
    marginTop: "8px"
  },
  statsRow: {
    display: "flex",
    flexDirection: "row",
    gap: "10px"
  },
  statBlock: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: tokens.colorNeutralBackground1,
    padding: "8px",
    borderRadius: "4px",
    alignItems: "center",
    minWidth: "80px"
  },
  label: {
    fontWeight: "bold",
  }
});

/**
 * Displays a small summary of the user's sessions in the date range:
 * - # of days doc found
 * - total active minutes
 * - total opportunity emails
 */
const UserSessionSummaryCard: React.FC<UserSessionSummaryCardProps> = ({
  user,
  sessions
}) => {
  const styles = useStyles();

  // 1) Count how many distinct days the user has a session doc
  const daySet = useMemo(() => {
    const s = new Set<string>();
    sessions.forEach(session => {
      s.add(session.date);
    });
    return s;
  }, [sessions]);
  const daysWithDoc = daySet.size;

  // 2) Sum active minutes
  const totalActiveMinutes = useMemo(() => {
    return sessions.reduce((sum, s) => sum + (s.totalActiveMinutes || 0), 0);
  }, [sessions]);

  // 3) Sum opportunity emails
  const totalOppEmails = useMemo(() => {
    return sessions.reduce((sum, s) => sum + (s.opportunityConversations?.length || 0), 0);
  }, [sessions]);

  // new: total non-biddable
  const totalNonBiddable = useMemo(() => {
    return sessions.reduce((sum, s) => sum + (s.uniqueSeen?.length || 0), 0);
  }, [sessions]);

  return (
    <Card className={styles.container}>
      <div>
        <Text size={400}>Session Summary for {user.firstName} {user.lastName}</Text>
      </div>

      <div className={styles.statsRow} style={{ marginTop: "8px" }}>
        <div className={styles.statBlock}>
          <Text size={300} className={styles.label}>Days Logged In</Text>
          <Text size={500}>{daysWithDoc}</Text>
        </div>
        <div className={styles.statBlock}>
          <Text size={300} className={styles.label}>Active (min)</Text>
          <Text size={500}>{totalActiveMinutes.toFixed(1)}</Text>
        </div>
        <div className={styles.statBlock}>
          <Text size={300} className={styles.label}>Opp Emails</Text>
          <Text size={500}>{totalOppEmails}</Text>
        </div>
        <div className={styles.statBlock}>
          <Text size={300} className={styles.label}>Seen (Non-Bid)</Text>
          <Text size={500}>{totalNonBiddable}</Text>
        </div>
      </div>

      {/* You could add more fields (like #bids, response times, etc.) if stored or aggregated. */}
    </Card>
  );
};

export default UserSessionSummaryCard;
