// EngagementScoreboard.tsx
import React, { useMemo } from "react";
import { makeStyles, Text, tokens } from "@fluentui/react-components";

interface UserDoc {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    officeLocation: string;
    team?: string;
    role: string;
  }

  interface SessionDoc {
    userId: string;
    date: string; // 'YYYY-MM-DD'
    totalActiveMinutes: number;
    opportunityConversations?: string[];
  }

interface EngagementScoreboardProps {
  users: UserDoc[];
  sessions: SessionDoc[];
  startDate: Date;
  endDate: Date;
}

const useStyles = makeStyles({
  container: {
    marginTop: "16px",
  },
  heading: {
    marginBottom: "8px",
    fontWeight: "500"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column"
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "8px",
  },
  th: {
    textAlign: "left",
    borderBottom: "2px solid #ccc",
    padding: "6px 8px",
    backgroundColor: tokens.colorNeutralBackground1,
  },
  td: {
    borderBottom: `1px solid ${tokens.colorNeutralForeground3}`,
    backgroundColor: tokens.colorNeutralBackground1,
    padding: "6px 8px",
  }
});

/**
 * This scoreboard calculates a simple engagement score for each user:
 *   - dailyLoginScore: #days doc found / #days in range * 30 pts
 *   - usageScore: sum totalActiveMinutes / some reference * 30 pts
 *   - opportunityScore: totalOpportunityEmails / userCount * 40 pts
 *
 * Then it sorts them descending by totalScore.
 * 
 * Feel free to refine or expand this model (e.g. add reply rate, response speed).
 */
const EngagementScoreboard: React.FC<EngagementScoreboardProps> = ({
  users,
  sessions,
  startDate,
  endDate
}) => {
  const styles = useStyles();

  // 1) Build an array of daily date strings in the range
  const dateStrings = useMemo(() => {
    const dates: string[] = [];
    const cur = new Date(startDate.getTime());
    while (cur <= endDate) {
      dates.push(cur.toISOString().split("T")[0]);
      cur.setDate(cur.getDate() + 1);
    }
    return dates;
  }, [startDate, endDate]);

  const totalDays = dateStrings.length;

  // 2) Create a quick lookup of session docs by user
  //    Also sum up totalActiveMinutes and opportunityEmails
  interface Stats {
    daysWithDoc: number;
    totalActiveMinutes: number;
    totalOppEmails: number;
  }
  const userStats = useMemo(() => {
    // Initialize a map: userId => stats
    const statsMap: Record<string, Stats> = {};
    users.forEach((u) => {
      statsMap[u.uid] = {
        daysWithDoc: 0,
        totalActiveMinutes: 0,
        totalOppEmails: 0,
      };
    });

    // Fill from sessions
    sessions.forEach((s) => {
      const st = statsMap[s.userId];
      if (st) {
        st.totalActiveMinutes += s.totalActiveMinutes || 0;
        st.totalOppEmails += s.opportunityConversations?.length || 0;

        // Check if this doc is a new day for them. We can track day strings in a set if needed.
        // For simplicity, we'll do an ad-hoc approach:
        // We'll assume each user only has 1 doc per day in sessions. If multiple, they'd still be "compliant" for that day. 
        // We'll count it once.
      }
    });

    // For day-by-day compliance, we'd need each day to do a doc check. 
    // Let's do a simpler approach: if session doc date is unique, increment day count
    // We'll build a set: userId => Set(dateStr)
    const userDays = new Map<string, Set<string>>();
    users.forEach(u => userDays.set(u.uid, new Set<string>()));

    sessions.forEach(s => {
      const ds = userDays.get(s.userId);
      if (ds) {
        ds.add(s.date);
      }
    });

    // Now update the statsMap with the set sizes
    userDays.forEach((setOfDays, userId) => {
      statsMap[userId].daysWithDoc = setOfDays.size;
    });

    return statsMap;
  }, [users, sessions, dateStrings]);

  // 3) Compute a naive score
  //    For example, up to 30 points for daily login compliance, up to 30 for usage, up to 40 for opportunity coverage
  //    This is purely an example. Tweak as needed.
  const scoreboard = useMemo(() => {
    return users.map(u => {
      const st = userStats[u.uid] || {
        daysWithDoc: 0,
        totalActiveMinutes: 0,
        totalOppEmails: 0
      };

      // dailyLoginScore
      let dailyLoginScore = 0;
      if (totalDays > 0) {
        const complianceRate = st.daysWithDoc / totalDays; // e.g. .6 for 6/10 days
        dailyLoginScore = complianceRate * 30;
      }

      // usageScore - let's assume 120 min/day is "ideal"
      // e.g. totalActiveMinutes / (120 * totalDays)
      let usageScore = 0;
      const idealUsage = 120 * totalDays; // 2 hours daily
      if (idealUsage > 0) {
        const usageRate = st.totalActiveMinutes / idealUsage;
        usageScore = Math.min(usageRate, 1) * 30; // max 30
      }

      // opportunityScore - let's define a max that's plausible. e.g. 1 per day per user => totalDays
      // if they found more than totalDays, we cap at 1 ratio 
      let opportunityScore = 0;
      const idealOppCount = totalDays; 
      if (idealOppCount > 0) {
        const oppRate = st.totalOppEmails / idealOppCount;
        opportunityScore = Math.min(oppRate, 1) * 40; // up to 40
      }

      const totalScore = dailyLoginScore + usageScore + opportunityScore;

      return {
        user: u,
        dailyLoginScore,
        usageScore,
        opportunityScore,
        totalScore
      };
    }).sort((a, b) => b.totalScore - a.totalScore); // sort descending
  }, [users, userStats, totalDays]);

  return (
    <div className={styles.container}>
        <div className={styles.headingContainer}>
        <Text size={500} className={styles.heading}>Engagement Scoreboard</Text>
        <Text size={300}>Sample formula weighting daily compliance, usage, and opportunities found.</Text>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>User</th>
            <th className={styles.th}>Login Score</th>
            <th className={styles.th}>Usage Score</th>
            <th className={styles.th}>Opp Emails Score</th>
            <th className={styles.th}>Total Score</th>
          </tr>
        </thead>
        <tbody>
          {scoreboard.map((row) => (
            <tr key={row.user.uid}>
              <td className={styles.td}>
                {row.user.firstName} {row.user.lastName}
              </td>
              <td className={styles.td}>{row.dailyLoginScore.toFixed(1)}</td>
              <td className={styles.td}>{row.usageScore.toFixed(1)}</td>
              <td className={styles.td}>{row.opportunityScore.toFixed(1)}</td>
              <td className={styles.td}>{row.totalScore.toFixed(1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EngagementScoreboard;
